.confirm-button {
    background: linear-gradient(90deg, #06081E -4.34%, rgba(6, 8, 30, 0) 100%),
    linear-gradient(93.56deg, #6821FF 9.39%, #248FF1 48.29%, #00F7CA 91.3%);
    height: 60px;
    font-family: Gilroy-Bold;
    color: rgba(255,255,255,1.0);
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    border-bottom-right-radius: 30px;

    &.disabled {
        background: linear-gradient(95.39deg, #6821FF 20.48%, #248FF1 59.61%, #00F7CA 99.56%);
        opacity: 0.2;
    }
}