.currently-in-use-table {
    display: flex;
    margin: 30px;
    flex: 1;

    &__wrapper {
        background: linear-gradient(180deg, #121a3d 0%,rgba(18,26,61,255) 40%, rgba(18,26,61,0) 100%)
    }

    &__link-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__header {
        display: flex;
        padding: 30px;
        padding-bottom: 0px;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;

        h1 {
            color: white;
            font-family: Gilroy-Semibold;
            font-size: 22px;
        }
    }
}



.filter-button {
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: 10px;
    position: relative;

    &:last-child {
        margin-right: 0px;
    }

    &__tag-wrapper {
        display: none;
        top: 50px;
        left: 50%;
        position: absolute;
    }

    &__tag {
        margin-left: -50%;
        display: flex;
        border-radius: 12px;
        padding: 12px 20px;
        font-family: Gilroy-Medium;
        font-size: 16px;
        line-height: 16px;
        color: white;
        white-space: nowrap;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(23px);
        border: 1px solid rgba(255,255,255,0.5);
    }

    &:hover {
        cursor: pointer;

        .filter-button__tag-wrapper {
            display: flex;
        }
    }
}

.formulation-table {

    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 450px;

    &__dot {
        height: 14px;
        width: 14px;
        border-radius: 100px;
        margin-right: 8px;
    }


    &__name {
        color: white;
        font-family: Gilroy-Regular;
        font-size: 16px;

        &.filtered {
            opacity: 0.1;
        }
    }

    &__inner {
        display: grid;
        flex: 1;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 60px;
        grid-auto-flow: row;
        margin-left: 2px;
        padding-top: 85px;
        overflow-y: scroll;
    }

    &__header {
        display: flex;
        color: white;
        border-bottom: 1px solid rgba(255,255,255,0.2);
        padding-bottom: 20px;
        margin-bottom: 40px;
        padding-top: 20px;
        font-family: Gilroy-Medium;
        font-size: 16px;
        position: absolute;
        left: 0px;
        right: 0px;
        z-index: 100;
        background: rgba(18,26,61,255)

    }
}


