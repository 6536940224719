.me-dashboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

         
    //delete later
    grid-template-rows: 0px 440px 190px 420px 450px min-content ;
    grid-template-areas: 
        "invite invite invite invite" 
        "my-score my-score my-result my-result" 
        "active-formulation total-sprays last-spray last-journal"
        "treehouse-trending treehouse-trending treehouse-latest treehouse-latest"
        "sleep-quality sleep-quality sleep-quality sleep-quality"
        "emotional-thermometer emotional-thermometer emotional-thermometer emotional-thermometer";
    
    @media screen and (max-width: 1200px) {
            grid-template-rows: 0px 440px 190px 420px 420px 450px min-content ;
            grid-template-areas: 
                "invite invite invite invite" 
                "my-score my-score my-result my-result" 
                "active-formulation total-sprays last-spray last-journal"
                "treehouse-trending treehouse-trending treehouse-trending treehouse-trending"
                "treehouse-latest treehouse-latest treehouse-latest treehouse-latest"
                "sleep-quality sleep-quality sleep-quality sleep-quality"
                "emotional-thermometer emotional-thermometer emotional-thermometer emotional-thermometer";
        
    }
    
    @media screen and (max-width: 1000px) {
        grid-template-rows: 0px 440px 440px 420px 420px 190px 190px 190px 190px 900px min-content ;
        grid-template-columns: repeat(1, 1fr);
    
        grid-template-areas: 
        "invite"
        "my-score"
        "my-result" 
        "treehouse-trending"
        "treehouse-latest"
        "last-journal"
        "total-sprays"
        "last-spray"
        "active-formulation"
        "sleep-quality"
        "emotional-thermometer";
    }

    


    
        

}


.me {
    &__alert {
        color: white;
        font-family: Gilroy-Bold;
        text-align: center;
        padding-top: 200px;
        height: 600px;
        vertical-align: center;
        font-size: 25px;
    }

    &__header {
        display: flex;
        grid-area: main-header;
        align-items: center;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__back {
        background-color: transparent;
        outline: none;
        border: none;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing:2px;
        align-self: center;
        font-family: Gilroy-Medium;
        width: 100px;

        
        &:hover {
            cursor: pointer;
        }

        img {
            margin-right: 10px;
        }
    }

    &__title {
        text-align: center;
        letter-spacing: -2%;
        text-transform: lowercase;
        vertical-align: center;
        font-size: 32px;
        font-family: Gilroy-Bold;
        color: white;
        grid-area: section-title;
        flex: 1;
    }


    &__spacing {
        width: 100px;
    }
}