

.sleep-circle {
    margin-top: -20px;
    height: 305px;
    width: 305px;
    border-radius: 200px;
    position:relative;
    border-width: 1px;

    &__top-text {
        color: gray;
        font-family: Gilroy-SemiBold;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1.4px;
        margin: 0px;
    }


    &__poor, &__amazing {
        color: white;
        position: absolute;
        font-family: Gilroy-Bold;
        font-size: 13px;
    }

    &__poor {
        top: 35%;
        left: -35px;
    }

    &__amazing {
        top: 98%;
        left: 55%;
    }
    
    &__border {
        position: absolute;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }


    &__inner {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
    }

    &__glow {
        position: absolute;
    }

    img {
        position: absolute;
    }

    @mixin eclipse-neutral {
        transform: translate(60%,-60%);
    }

    @mixin eclipse-poorly {
        transform: translate(20%,-20%);
    }

    &__eclipse {
        background: radial-gradient(41.44% 41.44% at 55.86% 44.34%, #12163A 0%, #06081E 100%);
        width: 292px;
        height: 292px;
        border-radius: 1000px;
        position: absolute;
        transform: translate(100%,-100%);
        transition: all 0.2s ease-in-out;

        &.neutral {
            @include eclipse-neutral();
            animation: show-eclipse-neutral 1s ease-in-out;
            animation-iteration-count: 1;
            animation-delay: 0s;
        }

        &.poorly {
            @include eclipse-poorly();
            animation: show-eclipse-poorly 1s ease-in-out;
            animation-iteration-count: 1;
            animation-delay: 0s;

        }
    }

    @keyframes show-eclipse-neutral {
        0% {
            transform: translate(100%,-100%);
        }

        100% {
            @include eclipse-neutral()
        }
    }

    @keyframes show-eclipse-poorly {
        0% {
            transform: translate(100%,-100%);
        }

        100% {
            @include eclipse-poorly();
        }
    }



    &__wrapper {
        position: absolute;
        overflow: hidden;
        border-radius: 1000px;
        
       
    }


    h1, p {
        font-size: 53px;
        font-family: Gilroy-Bold;
        z-index: 10000;
        -webkit-background-clip: text !important;
        background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        background: -webkit-linear-gradient(45deg, transparent 0%,rgba(255,255,255,0.05) 20%,rgba(255, 255, 255, 1) 100%);
    }

    &__hours.neutral, &__hours.great {
        background: -webkit-linear-gradient(45deg, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.3) 20%,rgba(255, 255, 255, 1) 100%);
    }

    h1 {
        position: relative;

        & > p {
            position: absolute;
            top: 35px;
            left: 38px;
            font-size: 20px;
        }
    }
}

