.gender-by-age {


    height: 100%;
    width: 90%;
    margin: auto;
    margin-top: -20px;

    &__labels {
        display: flex;
        top: 0;
    }

    &__label {
        font-family: Gilroy-Medium;
        font-size: 16px;
        color: white;
        margin-left: 32px;
        display: flex;
    }

    &__dot {
        height: 16px;
        width: 16px;
        border-radius: 50px;
        margin-right: 8px;
    }
}