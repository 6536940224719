.dashboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 190px 450px 450px 420px 190px 420px;
    gap: 20px;
    margin-left: 80px;
    margin-right: 80px;
  
    grid-template-areas: 
      "activated-pioneers active-today average-age total-journals"
      "sleep-indicator sleep-indicator sleep-indicator sleep-indicator"
      "pioneers-map pioneers-map pioneers-map pioneers-map"
      "currently-in-use currently-in-use avg-spray-time avg-spray-time"
      "total-sprays sprays-today avg-sprays-per-day bottle-lasts"
      "gender-by-age gender-by-age most-active most-active";
}
  

  

 