.formulations {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 420px 190px 600px;
    gap: 20px;
    margin-left: 80px;
    margin-right: 80px;
  
    grid-template-areas: 
        "currently-in-use currently-in-use average-spray-time average-spray-time"
        "total-sprays sprays-today avg-sprays-per-day bottle-lasts"
        "currently-in-use-table currently-in-use-table currently-in-use-table currently-in-use-table";

}