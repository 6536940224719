

.filter-modal {

    .tab-option {
        display: flex;
        font-family: Gilroy-SemiBold;
        color: white;
        margin-bottom: 20px;
        align-items: center;
        cursor: pointer;

        &:disabled {
            opacity: 0.5;
        }

        img {
            margin-right: 16px;
        }
    }

    &__separator {
        height: 1px;
        margin-top: 12px;
        background-color: rgba(255,255,255,0.2);
        margin-bottom: 15px;
        width: 100%;
    }

    .tab-button {
        
        padding: 0px;
        margin: 0px;
        margin-right: 10px;
        background-color: transparent;
        border: none;
        outline: none;
        font-family: Gilroy-SemiBold;
        font-size: 15px;
        color: rgba(255,255,255,0.3);
        cursor: pointer;
        
        &.selected {
            color: white;
        }
    }
    
    position: relative;

    &__mask {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 10;
    }

    &__modal {
        display: flex;
        flex-direction: column;
        padding: 15px;
        position: absolute;
        right: 0px;
        top: 40px;
        width: 300px;
        height: min-content;
        min-height: 150px;
        border-radius: 12px;
        border: 1px solid;
        background: rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(23px);
        border: 1px solid rgba(255, 255, 255, 0.19);
        z-index: 11;
    }

    &__icon {
        cursor: pointer;
    }

}