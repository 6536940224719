.table {
    display: flex;
    padding-right: 30px;
    margin-top: 5px;

    &__column {
        flex: 1;
        display: grid;
        grid-auto-rows: 30px;
        grid-gap: 35px;
        margin-left: 30px;
        
    }

    &__item {
        font-family: Gilroy-Medium;
        font-size: 16px;
        color: white;
        display: flex;
        align-self: center;
        align-items: center;
    }

    &__column-title {
        font-family: Gilroy-Medium;
        font-size: 16px;
        color: white;
    }
}