.webgla {
	background: radial-gradient(68.87% 68.87% at 50% 50%, #011557 0%, #010623 69.58%, #01010A 95.58%, #000000 100%);
	transition: 0.5s ease;
    position: fixed;
    z-index: -10;
    opacity: 1;
}

.webgla__mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(68.87% 68.87% at 50% 50%, #011557 0%, #010623 69.58%, #01010A 95.58%, #000000 100%);
	opacity: 0.8;
    z-index: -8;
}