.journalling-status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    height: 100%;

    & > * {
        width: 130px;
    }
}