.download-all-data {
    display: flex;
    margin-right: 30px;
    margin-left: 30px;
    align-items: center;

    &__button {
        cursor: pointer;
        font-family: Gilroy-Regular;
        color: white;
        background-color: rgba(255,255,255,0.2);
        border: 1px solid white;
        border-radius: 20px;
        padding: 5px 20px;
        margin-right: 10px;
        outline: none;
    }
}