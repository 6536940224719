.pioneer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

         
    //delete later
    grid-template-rows: 440px 190px 420px 1050px 530px min-content ;
    grid-template-areas: 
        "my-score my-score my-result my-result" 
        "active-formulation total-sprays last-spray last-journal"
        "sleep-quality sleep-quality sleep-quality sleep-quality"
        "hourly-uses hourly-uses hourly-uses hourly-uses"
        "about about survey survey"
        "all-surveys all-surveys all-surveys all-surveys";
        
    @media screen and (max-width: 1200px) {
            grid-template-rows: 440px 190px 420px 1050px 530px min-content  ;
            grid-template-areas: 
            "my-score my-score my-result my-result" 
            "active-formulation total-sprays last-spray last-journal"
            "sleep-quality sleep-quality sleep-quality sleep-quality"
            "hourly-uses hourly-uses hourly-uses hourly-uses"
            "about about survey survey"
            "all-surveys all-surveys all-surveys all-surveys";
            
        
    }
    
    @media screen and (max-width: 1000px) {
        grid-template-rows: 440px 440px 190px 190px 190px 190px 950px 530px 530px 1050px;
        grid-template-columns: repeat(1, 1fr);
    
        grid-template-areas: 
        "my-score"
        "my-result" 
        "last-journal"
        "total-sprays"
        "last-spray"
        "active-formulation"
        "sleep-quality"
        "about"
        "survey"
        "hourly-uses";
    }


    &__header {
        font-family: Gilroy-Bold;
        color: white;
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        button {
            background-color: transparent;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            font-family: Gilroy-Regular;
            font-size: 14px;
            color: white;
            flex: 1;
            align-items: center;

            img {
                transform: rotate(180deg);
                margin-right: 5px;
            }
        }

        h1 {
            text-align: center;
        }
    }


    
        

}

