.forgot-password {

    display: flex;
    flex-direction: column;
    margin: 0px;
    margin: auto;
    margin-top: 50px;
    min-height: 80vh;

    @media screen and (min-width: 700px) {
        margin-left: 200px ;
    }

    

    h1 {
        font-family: Gilroy-Bold;
        font-size: 40px;
        color: white;
    }

    & > *:nth-child(2), & > *:nth-child(1)  {
        margin-bottom: 40px;
    }

    & > *:nth-child(3) {
        margin-bottom: 16px;
    }

    & > *:nth-child(4) {
        margin-bottom: 40px;
    }

    & > *:nth-child(5) {
        margin-bottom: 80px;
    }


    &__forgot-password {
        font-family: Gilroy-Medium;
        font-size: 16px;
        padding-left: 15px;
        color: white;
        opacity: 0.7;
        text-decoration: none;
    }
}