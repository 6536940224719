.doughnut {
    &__labels {
        margin-right: 50px;
    }

    &__label:last-child {
        margin-bottom: 0px;
    }

    &__label {
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        &-dot {
            height: 18px;
            width: 18px;
            border-radius: 500px;
        }

        &-title, &-value {
            font-family: Gilroy-semibold;
            color: rgba(255,255,255,0.8);
        }

        &-value {
            font-size: 32px;
            line-height: 30px;
        }

        &-title {
            font-size: 20px;
            line-height: 10px;
            margin-right: 40px;
            margin-left: 18px;
        }

        
    }

    
}