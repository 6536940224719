@mixin positionAbsolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.invite {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }

    &__background {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.5;

        @media screen and (max-width: 1000px) {
            right: 0;
            left: 0;
            bottom: 0;
            top: 0;
            height: 100%;
            opacity: 0.1;
        }
    }

    &__moon {
        margin-left: -40px
    }

    &__friend {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 10px;
        margin-bottom: 40px;
        grid-template-areas: 'name active reward';

        @media screen and (max-width: 400px) {
            grid-template-areas: 'name reward';
            grid-template-columns: repeat(2, 1fr);
        }

        & > * {
            text-align: start;
            color: white;
            font-family: Gilroy-Regular;
        }
    }

    &__name {
        grid-area: name;
    }

    &__active {
        grid-area: active;

        @media screen and (max-width: 400px) {
            display: none;
        }
    }

    &__reward {
        grid-area: reward;
    }

    &__friends {
        flex: 1;
        z-index: 40;
        display: flex;
        flex-direction: column;
        max-height: 60%;
        overflow-y: scroll;

        @media screen and (max-width: 1000px) {
            margin-left: 40px;
            margin-right: 40px;
            margin-top: 40px;
            padding-top: 40px;
            border-top: 1px solid rgba(255,255,255,0.3);
            max-height: 300px;
        }

        @media screen and (min-width: 1000px) {
            align-self: center;
        }

        h2 {
            font-family: Gilroy-Bold;
            color: white;
            font-size: 28px;
            margin: 0px !important;
            margin-bottom: 30px !important;
        }
        
        
        
    }

    &__prompt {
        flex: 0.8;
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        z-index: 40;

        h1 {
            font-family: Gilroy-Bold;
            color: white;
            font-size: 62px;
            margin: 0px !important;

            @media screen and (max-width: 1000px) {
                margin-top: 100px !important;
            }
        }

        h3 {
            margin: 0px;
            font-family: Gilroy-Bold;
            color: white;
            font-size: 20px;
            margin-top: 12px;
            margin-bottom: 40px;
            max-width: 300px;
        }

        button {
            min-width: min(400px, 80%);
            margin-bottom: 60px;
        }

        p {
            font-family: Gilroy-Regular;
            color: white;
            font-size: 14px;
            opacity: 0.5;
            max-width: 400px;
        }
    }
}