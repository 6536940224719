.user-sleep-indicator {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;

    @media screen and (max-width: 900px) {
        flex-direction: column !important;
        & > * {
            margin-bottom: 15px;
        }
    }

}