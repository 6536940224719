.single-bar-chart {

    display: flex;
    flex-direction: column;
    flex: 1;

    &__wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 1px;
        flex: 1;
    }

    &__bar {
        width: 20px;
        border-radius: 4px;
    }

    &__underline {
        background-color: white;
        height: 1px;
        margin-top: 2px;
        margin-bottom: 16px;
        opacity: 0.5;
    }

    &__ages {
        text-align: center;
        font-size: 14px;
        color: white;
        font-family: Gilroy-Medium;
        letter-spacing: 1px;
    }

}


#bar-chart {
    display: flex;
}