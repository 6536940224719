


$border-width: 1px;
$border-color: rgba(255,255, 255,0.6);
$border-color-unselected: white;
$border-radius: 12px;

.one-line-input {
    height: 60px;
    display: flex;
    position: relative;

    &__input-wrapper {
        position: absolute;
        top: 19px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        background-color: transparent;
        font-family: Gilroy-Medium;
        align-items: center;
        

        input {
            width: 100%;
            outline: none;
            padding-top: 2px;
            padding-bottom: 1px;
            padding-left: 5px;
            font-size: 16px;
            border: none;
            color: white;
            background-color: transparent !important;
            font-family: Gilroy-Medium !important;
        }
    }

    

    &__trailing {
        flex: 1;
    }

    &__notch {
        width: min-content;
    }

    &__leading {
        width: 16px;
        border-left: $border-width solid $border-color ;
        border-right: none;
        border-top: $border-width solid $border-color ;
        border-bottom: $border-width solid $border-color ;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    &__notch {
        position: relative;
        border-bottom: $border-width solid $border-color ;
    }

    &__trailing {
        width: 20px;
        border-right: $border-width solid $border-color ;
        border-top: $border-width solid $border-color ;
        border-bottom: $border-width solid $border-color ;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    &__legend {
        margin-top: -8px;
        color: $border-color;
        font-size: 0px;
        padding-right: 0px;
        padding-left: 0px;
        font-family: Gilroy-Semibold;
        transition: font-size 0.2s;
        white-space: nowrap;

        &.focused {
            font-size: 14px;
            padding-right: 2px;
            padding-left: 2px;
        }
    }

    &__eye {
        margin-left: 10px;
        
        &:hover {
            cursor: pointer;
        }
    }
};

input {
    &:-webkit-autofill::first-line,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        font-family: Gilroy-Medium;
        font-size: 16px;
        border-radius: 5px !important;
    }
}