@mixin background-position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.card {
    position: relative;
    border-radius: 16px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header {
        position: absolute;
        top: 30px;
        left: 30px;
        right: 30px;
        z-index: 1000000;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-family: Gilroy-Medium;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: white;
        opacity: 0.5;
        text-align: left;
    }
    
    &__children {
        display: flex;
        flex-direction: column;
        z-index: 1000;
        height: 100%;
        width: 100%;
        transform: translateZ(100px);
    }

    &__background {
        z-index: 2;
        overflow: hidden;
        border-radius: 16px;
        background-image: url('../../../assets/noise.png');
        mix-blend-mode: overlay;
        opacity: 1;
        @include background-position();
        pointer-events: none;
    }

    &__background-gradient {
        overflow: hidden;
        pointer-events: none;
        border-radius: 16px;
        @include background-position();
        z-index: 1;
        backdrop-filter: blur(4px);
        background: linear-gradient(162.06deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%)
    }
}