@mixin background-position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

header {
    height: 100px;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    padding-right: 80px;
    padding-left: 80px;
    justify-content: space-between;
    z-index: 10000000 !important;
    position: fixed;
    left: 0;
    right: 0;

    @media screen and (max-width: 1100px) {
        padding-right: 40px;
        padding-left: 40px;
    
    }
}

.header {

    &__row {
        display: flex;
        align-items: center;

        & > * {
            margin-right: 20px;
        }

        
        @media screen and (max-width: 1100px) {
            & > *:last-child {
                margin-right: 0px;
            }
        }


    }

    &__hidden-menu, &__hidden-menu-mask, &__overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }

    &__hidden-menu {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        a {
            text-decoration: none;
            font-family: Gilroy-Bold;
            color: white;
            font-size: 40px;
            margin-bottom: 30px;
            transition: all 1s ease-in-out;

            &.hidden {
                opacity: 0;
                transform: translateY(40px) ;
            }

            &.visible {
                opacity: 1;
                transform: translateY(0px) ;
            }

            &.selected {
                opacity: 0.5;
                pointer-events: none;
            }

            &.selected.hidden {
                opacity: 0;
            }
        }
    }

    &__overlay {
        overflow-y: scroll;
        position: fixed;
        display: none;
        z-index: 10000000;
        &.visible {
            display: initial;
        }

        @media screen and (min-width: 1100px) {
            display: none;
        }
    }

    &__hidden-menu-mask {
        background-color: rgba(0, 0, 0 ,0.3);
        backdrop-filter: blur(10px);
        pointer-events: none;
        scroll-behavior: non;
        overflow-y: scroll;
        transition: all 0.5s ease-in-out;
        z-index: 1;

        &.hidden {
            backdrop-filter: blur(0px);
            background-color: rgba(0, 0, 0 ,0.0);
        }

        &.visible {
            backdrop-filter: blur(10px);
            background-color: rgba(0, 0, 0 ,0.4);
        }
    }

    &__menu {
        @media screen and (min-width: 1100px ){ 
            display: none;
        }
        cursor: pointer;
    }

    &__mid-content {
        @media screen and (max-width: 1100px) {
            display: none;
        }

        display: flex;
        flex-direction: row;
        & > * {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
    
    &__profile-pic {
        height: 40px;
        width: 40px;
        border-radius: 30px;
        border: 1px solid rgba(255, 255, 255, 0.9);
        cursor: pointer;
        object-fit: cover;
    }

    &__title {
        text-align: center;
        letter-spacing: -2%;
        text-transform: lowercase;
        vertical-align: center;
        font-size: 32px;
        font-family: Gilroy-Bold;
        color: white;
        grid-area: section-title;
    }

    &__logo-wrapper {
        flex: 1;
        z-index: 10000;

        img {
            cursor: pointer;
        }
    }

    &__end-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 10000;
    }

    &__mid-wrapper {
        display: flex;
        grid-auto-columns: auto;
        grid-template-rows: auto;
        z-index: 10000;

        & > * {
            margin-right: 40px;
        }

        & > *:last-of-type {
            margin-right: 0px;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        text-decoration: none;
        color: white;
        font-family: Gilroy-Semibold;
        letter-spacing: 0.01em;
        opacity: 0.6;
        
        img {
            display: none;
        }

        &.selected {
            opacity: 1;

            img {
                display: initial;
                position: absolute;
                top: -40px;
            }
        }
        
    }


    &__background-gradient {
        pointer-events: none;
        z-index: 100;
        backdrop-filter: blur(20px);
        @include background-position();
        background: rgba(6, 8, 30, 0.20);
        transition: opacity 0.2s;
        opacity: 1;
        -webkit-mask: linear-gradient(to bottom, white 0%, white 75%, rgba(255 ,255, 255, 0.0) 100%);


        &.disabled {
            opacity: 0;
        }
    }
}