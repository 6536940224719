.message-modal {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: fixed;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;

    &__background {
        position: fixed;
        display: flex;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background: rgba(255, 255, 255, 0.06);
        backdrop-filter: blur(4px);
    }

    &__contents {
        background: #06081E;
        width: 355px;
        z-index: 100;
        border-radius: 12px;
        z-index: 1000;
    }
}

.default-message {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 30px;
    grid-template-rows: 130px 30px;
    grid-template-areas: 
        "prompt prompt"
        "yes no";

    h4 {
        font-family: Gilroy-Regular;
        font-size: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: white;
        grid-area: prompt;
        margin: auto;
        padding-right: 20px;
        padding-left: 20px;
    }

    &__button {
        font-family: Gilroy-Bold;
        font-size: 20px;
        line-height: 132%;
        text-align: center;
        color: #FFFFFF;
        background-color: transparent;
        overflow: hidden;
        border: hidden;
        cursor: pointer;
    }

    &__yes, &__no {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    &__yes {
        grid-area: yes;
    }

    &__no {
        grid-area: no;
    }
}