.my-result {
    display: flex;
    flex-direction: column;

    &__nav-bar {
        display: flex;
        flex-direction: row;
        padding: 20px;

        h1 {
            padding: 0px;
            margin-bottom: 0px !important;
        }
    }

    &__waiting-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;

        h1 {
            font-family: Gilroy-Bold;
            color: white;
        }
    }

    h1 {
        color: white;
        font-family: Gilroy-Bold;
        margin-left: 20px;
        opacity: 1;
        transition: all 0.2s;
        cursor:default;

        @media screen and (max-width: 1400px) {
            font-size: 28px !important;
        }

        @media screen and (max-width: 1200px) {
            font-size: 22px !important;
        }


        @media screen and (max-width: 600px) {
            font-size: 20px !important;
        }
        
        &.unselected {
            opacity: 0.3;
            cursor: pointer;
            transition: all 0.2s;
        }
    }

    &__contents-wrapper {
        position: relative;
        flex: 1;
    }

    &__background {
        position: absolute;
        z-index: 0;
        top: 20px;
    }

   

    &__headline {
        display: flex;
        margin-bottom: 80px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__description {
        position: absolute;
        left: 0px;
        bottom: 10px;
        overflow: hidden;
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        p {
            height: min-content;
        }
    }

    &__contents {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        font-family: Gilroy-Thin;
        transform: translateY(0px);
        transition: all 0.3s;
        transition-delay: 0.3s;
        position: absolute;
        flex: 1;

       

        h2 {
            z-index: 1;
            font-size: 50px;
            margin: 0px;
        }


        p {
            z-index: 1;
            max-width: 60%;
            font-family: Gilroy-Bold;
            color: white;
            font-size: 15px;
            
        }

        &.unselected {
            opacity: 0;
            transform: translateY(300px);
            transition: all 0.3s;
        }
    }

    
    

    &__horizontal {
        display: flex;
        padding: 40px !important;
        padding-left: 10px !important;
        padding-top: 0px !important;
        flex: 1;
        align-items: center;
    }

    &__title {
        font-family: Gilroy-Bold;
        font-size: 32px;
        color: white !important;
        padding-top: 40px;
        padding-left: 40px;
        margin: 0px;;
    }

    &__right {
        width: 310px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;

        p {

            word-wrap: break-word;
            padding: 0px;
            width: 310px;
            font-family: Gilroy-Bold;
            font-size: 30px;
            margin-bottom: 20px;
            color: white;

            strong {
                background: -webkit-linear-gradient(3.06deg, #6821FF -33.08%, #0085FF 33.02%, #00D9F6 100.51%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        
    }

    &__left {
        flex: 1;
    }

    &__arrow {
        align-self: flex-start;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 10px;
    }

    &__mood-headline {
        margin-bottom: 80px;
        min-height: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 100;

        & > div {
            display: flex;
            height: 60px;

            &:first-child {
                margin-right: 15px;
            }
            
            h1 {
                font-family: Gilroy-Thin;
                font-size: 50px !important;
                line-height: 65px !important;
                height: 0px !important;
                margin: 0px !important;
            }
        }
        
       

    }
}