.pioneer-link {
    
    color: white;
    font-family: Gilroy-Medium;
    font-size: 17px;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    box-sizing: border-box;
    border-bottom: 0px solid white;
    

    &__underline {
        position: absolute;
        bottom : -5px;
        left: 0;
        right: 0;
        height: 0px;
        background-color: white;
    }

    &__wrapper {
        display: flex;
        position: relative;

        &:hover {
            .pioneer-link__underline {
                height: 3px
            }
        }
    }
    
}