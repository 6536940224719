.plain-value {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    align-items: space-between;
    justify-content: flex-start;
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    
    h1 {
        margin: 0px;
        font-family: Gilroy-Thin;
        font-size: 80px;
        line-height: 60px;
        color: white;
        flex: 1;
        display: flex;

        * {
            margin: 0px;
            font-family: Gilroy-Thin;
            font-size: 40px;
            line-height: 60px;
            color: white;
            flex: 1;
            margin-left: 10px;
            margin-top: 12px;
        }
    }

    


    h4 {
        margin: 0px;
        font-family: Gilroy-Light;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        color: white !important;
        opacity: 0.5;
    }
}