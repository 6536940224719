@mixin position {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.loading-pane {
    @include position;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    z-index: 1000000000000;
    transform: translateZ(100px);
}