.terms {
    flex-direction: column;
    width: 70vw;
    margin: auto;
    line-height: 30px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 800px) {
        width: 85vw;
    }

    & > * {
        color: white;
    }

    p, li {
        font-family: Gilroy-regular;
    }

    h1, h2, h3 {
        font-family: Gilroy-bold;
    }

    h1, h2 {
        text-align: center;
        margin-bottom: 30px;
    }

    &__confirm-button-wrapper {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-self: flex-end;

        @media screen and (max-width: 550px) {
            width: 100%;
        }
    }
}
