.all-pioneers {
    

    &__wrapper {
        display: flex;
        padding: 30px;
        flex-direction: column;
    }

    

    &__title {
        color: white;
        font-family: Gilroy-Semibold;
        font-size: 22px;
    }

    &__header {
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__search-wrapper {
        display: flex;
        border-radius: 12px;
        align-items: center;
        padding: 17px;
        border: 1.5px solid rgba(255,255,255,0.9);
        width: 250px;
        transition: width 0.4s, border 0.4s;
        overflow: hidden;

        input {
            background-color: transparent;
            outline: none;
            border: none;
            flex: 1;
            margin-left: 15px;
            margin-right: 15px;
            color: white;
            font-family: Gilroy-Medium;
            font-size: 16px;
            transition-delay: 0.1s;
            transition: opacity 0.3s;
        }


        &.disabled {
            width: 20px;
            border: 1.5px solid rgba(255,255,255,0.0);

            .all-pioneers__clear-search {
                opacity: 0;
                transition: opacity 0.3s;
                transition-delay: 0.1s;
                pointer-events: none;
            }

            input {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &__toggle-search:hover {
        cursor:pointer
    }



    &__clear-search {
        opacity: 1;
        cursor: pointer;
    }

}   


.all-pioneers {
    
    &__table {
        padding: 30px;
        display: flex;
    }
    
    &__column {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__column-title {

        &:disabled > * {
            opacity: 0.5;
        }
        background-color: transparent;
        border: none;
        display: flex;
        padding: 0px;
        justify-content: flex-start;
        align-items: center;
        outline: none;
        font-family: Gilroy-Medium;
        font-size: 16px;
        color: rgba(255,255,255,0.5);
        border-bottom: 1px solid rgba(255,255,255,0.2);
        padding-bottom: 20px;
        cursor: pointer;

        &.selected {
            color: rgba(255, 255, 255, 1);
        }

        img {
            margin-left: 5px;
        }
    }

    &__column-data {
        margin-top: 35px;
        display: flex;
        flex-direction: column;
    }

    &__data {
        color: white;
        height: 60px;
        font-family: Gilroy-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;
    }


    &__dot {
        height: 14px;
        width: 14px;
        border-radius: 100px;
        margin-right: 10px;
    }

    &__no-result {
        color: white;
        font-family: Gilroy-Bold;
        text-align: center;
        margin-top: 150px;
        margin-bottom: 150px;
    }

    &__line {
        height: 1px;
        background-color: rgba(255,255,255,0.2);
        margin-right: 20px;
        margin-left: 20px;
        
    }

    &__show-more {
        cursor: pointer;
        margin:auto;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        font-family: Gilroy-Bold;
        color: white;
        font-size: 20px;
    }

    &__data {

    }
}