.dropdown-clean {

    
    position: relative;

    &__mask {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 10;
    }

    &__box { 
        color: white;
        font-family: Gilroy-SemiBold;
        font-size: 14px;
        width: min-content
    }

    &__options {
        border: 1px solid rgba(255,255,255,0.05);
        background: linear-gradient(0deg, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.02) 100%);
        border-radius: 10px;
        backdrop-filter: blur(5px);
        padding: 8px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        color: white;
        font-size: 14px;
        font-family: Gilroy-SemiBold;
        backdrop-filter:blur(30px);
    }

    

    &__options {
        backdrop-filter: blur(15px);
        background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.9) 100%);
        box-shadow: inset 1px 0px 16px 0px rgb(0 0 0 / 30%);
        color: rgba(0,0,0,0.8)
    }

    &__box {
        cursor: pointer;
    }

    &__options {
        position: absolute;
        top: 40px;
        z-index: 100;
        max-height: 300px;
        overflow: scroll;
        cursor: pointer;
    }

    &__option {
        margin-top: 15px;
        cursor: pointer;

    
        &:nth-of-type(1) {
            margin-top: 10px !important;
        }

        &:last-child {
            margin-bottom: 10px !important;
        }
    }
}