.over-wrapper {
    position: absolute;
    display: flex;
    overflow: scroll;
    width: 100%;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 40px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
        

        &::-webkit-scrollbar {
            display: none;
        }

    
}

.hourly-uses {
    display: flex;
    padding-top: 40px;
    width: 100%;
    overflow-y: scroll;

    &__title {
        font-family: Gilroy-Bold;
        color: white;
        margin-left: 30px !important; 
        margin: none;
        margin-bottom: 50px;
    }

    &__sign {
        z-index: 1000;
        position: absolute;
        color: white;
        font-family: Gilroy-Regular;
        font-size: 15px;
        left: -14px;

        border: 1px solid black;
        background-color: rgba(255,255,255,0.4);
        padding: 10px;
        border-radius: 5px;
        border-image-source: linear-gradient(0deg, rgba(95, 149, 255, 0) 57.82%, rgba(7, 146, 224, 0.2) 100%);

        width: 80px;
        
        flex-direction: column;
        align-items: center;

        h3, h4 {
            margin: 0px;
            padding: 0px;
        }

        h4 {
            font-size: 11px;
            white-space: nowrap;
        }

    }

    &__x-axis-tick {
        color: rgba(255,255,255,0.5);
        font-family: Gilroy-Bold;
        font-weight: 800;
        font-size: 10px;
        margin-top: 60px;
        text-align: center;
    }

    &__grid-wrapper {
        overflow-x: scroll;
        display: flex;
        flex-direction: row;
        width: 50px;

        
    }

    &__title {
        font-size: 28px;
        font-family: Gilroy-Bold;
        color: white;
    }

    &__subtitle {
        font-size: 16px;
        font-family: Gilroy-Medium;
        color: white;
        opacity: 0.5;
        margin-top: 10px;
        letter-spacing: 0.5px;
    }

    &__formulations {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > * {
            flex: 1;
        }

        
    }

    &__y-axis {
        padding-left: 30px;
        @media screen and (max-width: 500px) {
            padding-left: 10px;
        }
    }

    &__tag {
        position: fixed;
        top: 100px;
        left: 100px;
        height: 100px;
        width: 200px;
        z-index: 10000000;
        border-radius: 12px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px rgba(1,128,245,0.4) solid;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(3px);
    }


    &__table {
        display: flex;
        overflow: scroll;
        height: 100%;
        position: relative;
        -ms-overflow-style: none; 
        scrollbar-width: none;
        flex: 1;

        &::-webkit-scrollbar {
            display: none;
        } 
    }

    &__y-axis-tick {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &  > img {
            @media screen and (max-width: 500px) {
                height: 20px;
            }
        }
    }

    &__tick-hour {
        margin-left: 10px;
        font-family: Gilroy-Regular;
        color: white;
        font-size: 16px;

        @media screen and (max-width: 500px) {
            font-size: 13px;
            margin-left: 5px;
        }
    }

    &__column {
        height: 100%;
        display: flex;
        flex-direction: column;

        &:last-child {
            margin-right: 50px;
        }
    }

    &__lines {
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__footer {
        margin-top: 40px;
        color: white;
        display: flex;
        font-family: Gilroy-Medium;
        font-size: 14px;
        align-items: center;
        justify-content: center;
    }

    &__inner-table {
        display: flex;
    }

    &__hour {
        height: 2px;
        width: 60%;
        border-radius: 10px;

        &-wrapper {
            box-sizing: border-box;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
}