.loading-ui {
    display: grid;
    opacity: 1;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 250px 300px 420px 190px;
    grid-template-areas: 
        "g1 g1 g2 g2"
        "g3 g5 g2 g2"
        "g6 g6 g6 g6"
        "g4 g4 g4 g4";
   
    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 250px 300px 420px 190px 200px 400px;
        grid-template-areas: 
            "g1"
            "g2"
            "g3"
            "g6"
            "g4"
            "g5";
   
    }

    .loading-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}