.message-context {
    &__wrapper {
        position: fixed;
        top: 0px;
        right: 0px;
        height: 100vh !important;
        width: 100vw;
        z-index: 1000000000;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 20px;
        pointer-events: none;
        max-width: 80%;
    }

    &__message {
        background: linear-gradient(95.39deg, #6821FF 20.48%, #248FF1 59.61%, #00F7CA 99.56%);
        backdrop-filter: blur(37px);
        border-radius: 12px;
        margin-bottom: 10px;
        transition-property: opacity, transform;
        transform: translateX(100px);
        opacity: 0;

        &.visible {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    &__text {
        max-width: 80%;
    }

    &__contents {
        z-index: 100000;
        box-sizing: border-box;
        border-radius: 12px;
        font-family: Gilroy-Medium;
        color: white;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-right: 10px;
            margin-left: 10px;
        }

        &.hidden {
            visibility: hidden;
            padding: 16px 25px;
        }
    }

    &__blur {
        border-radius: 11px;
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        display: flex;
        background: linear-gradient(95.39deg, #6821FF 20.48%, #248FF1 59.61%, #00F7CA 99.56%);
        align-items: center;
        justify-content: center;
    }


    &__blur:after {
        content: '\A';
        position: absolute;
        width: 100%; 
        height:100%;
        top:0; 
        left:0;
        background:rgba(0,0,0,0.5);
        border-radius: 11px;
    }
}