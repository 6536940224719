.survey-card {

    font-family: Gilroy-SemiBold;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 32px;
    height: 255px;
    position: relative;

    @media screen and (max-width: 500px) {
        height: min-content;
        min-height: 255px;
    }

    h1 {
        margin: 0px;
        font-size: 30px;
        margin-bottom: 24px;
    }

    p {
        margin: 0px;
        font-family: Gilroy-Light;
        font-size: 18px;
        line-height: 28px;
        opacity: 0.7;

        @media screen and (max-width: 500px) {
            margin-bottom: 20px;
        }

        @media screen and (max-width: 1200px) {
            font-size: 15px;
        }
    }

    a {
        font-family: Gilroy-SemiBold;  
        cursor: pointer; 
        text-decoration: none;
        color: white
    }

    &__survey-done {
        opacity: 0.5;
    }

    &__done-check {
        position: absolute;
        top: 32px;
        right: 32px;
        height: 30px;
    }

    &__survey-link {
        display: flex;
        margin-top: 10px;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        img {
            height: 15px;
            margin-left: 20px;
        }
    }

}