.user-data {
    position: relative;

    input[type="file"] {
        display: none;
    }

    &__photo {
        position: relative;
        align-self: flex-start;
    }

    label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__icon {
        height: 30px;
        width: 30px;
        transform: scale(0);
    }

    label {
        &:hover {
            .user-data__icon {
                transform: scale(1);
                transition: all 0.3s;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: 40px;
        width: 310px;

        & > .one-line-input {
            margin-bottom: 40px;
        }
    
        & > .one-line-input:last-child {
            margin-bottom: 0px;
        }
        
        h1 {
            font-family: Gilroy-Bold;
            color: white;
            font-size: 32px;
            margin-bottom: 40px;
            margin-top: 20px;
        }
    }

    &__signout {
        position: absolute;
        right: 32px;
        top: 32px;
        cursor: pointer;
    }


    &__profile-pic {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 1000px;
        background-color: #0C0E2D;
        border: 1px solid #FFFFFF66;
    }

}