.main-pioneer-data {
    display: flex;
    flex-direction: column;
    padding-top: 50px !important;
    padding-left: 50px !important;
    width: 100%;
    height: 100%;

    h1 {
        margin-top: 0px;
        margin-bottom: 40px;
        font-size: 32px;
        color: white;
        font-family: Gilroy-Bold;
    }

    &__about-field {
        padding-bottom: 20px;
        display: flex;
    }

    &__field-name {
        font-size: 16px;
        color: white;
        opacity: 0.5;
        font-family: Gilroy-Regular;
        max-width: 70%;
    }

    &__field-data {
        position: absolute;
        left: 200px;
        min-width: 300px;
        font-family: Gilroy-Regular;
        color: white;
        font-size: 16px;
        word-wrap: break-word;
    }

    &__survey-field {
        margin-bottom: 40px;
    }

    &__field-answer {
        min-width: 300px;
        font-family: Gilroy-Bold;
        color: white;
        font-size: 16px;
        width: 90%;
        margin-top: 8px
    }

}

.all-surveys {
    display: flex;
    min-height: min-content;
    width: min-content;

    
    &__dot {
        height: 15px;
        width: 15px;
        border-radius: 30px;
    }


    &__wrapper {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
        width: 100%;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__survey {
        width: 380px;

        @media screen and (max-width: 400px) {
            width: 80vw;
        }

        padding-left: 24px !important;
        padding-right: 24px !important;
        padding-top: 24px !important;
        padding-bottom: 24px !important;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        font-family: Gilroy-Bold;
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid;

        h3 {
            font-size: 20px;
            padding: 0px;
            margin: 0px;
            font-family: Gilroy-Regular;
            flex: 1;
            text-align: end;
            padding-right: 10px;
        }

        h2 {
            font-size: 20px;
            padding: 0px;
            margin: 0px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            flex: 1.5;
        }
    }
}