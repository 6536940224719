.science {
    display: flex;
    flex-direction: column;

    h1 {
        margin-top: 40px;
        font-family: Gilroy-Bold;
        color: white;
        font-size: 32px;

        &:first-of-type {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 950px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    &__cards {
        
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;


        @media screen and (max-width: 950px) {
            grid-template-columns: 1fr;
        }
    }


}