.grid {
    

}

.emotional-thermometer { 
    height: 100%;
    width: 100%;

    &__message-wrapper {
        position: absolute;
        bottom: 100px;
        left: -42px;
        z-index: 1000;
    }

    &__message {
        position: relative;
    }

    &__message-contents {
        position: absolute;
        pointer-events: none;
        top: 15px;
        color: white;
        font-family: Gilroy-Regular;
        height: 60px;
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 150px;
        margin-left: 10px;
        margin-right: 10px;

        b {
            font-family: Gilroy-Bold;
        }

        h1 {
            font-size: 14px;
            margin: 0px;
            text-transform: capitalize;
            
            b {
                font-size: 15px;
            }
        }

        p {
            margin: 0px;
            margin-top: 5px;
            font-size: 12px;
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        margin-bottom: 30px;
        justify-content: center;
        margin-left: 15px;
        margin-right: 15px;

        @media screen and (max-width: 500px) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    &__character {
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 20px;
    }

    &__sofiji-wrapper {
        position: absolute;
        top: -20px;
        width: 100%;
        height: 100%;
        z-index: 1000;
        display: flex;
        justify-content: center;
    }

    &__sofiji {
        width: 50px;
    }

    &__glass {
        width: 80px;
    }

    &__header {
        font-family: Gilroy-Bold;
        color: white;
        margin: 0px;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        display: flex;
        align-items: center;
        h1 {
            margin: 0px;
            font-size: 32px;
            margin-top: 34px;
            
            @media screen and (max-width: 850px) {
                font-size: 20px !important;
                margin-top: 0px;
            }
        }
    }
}

.emotional-intensity {
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: 850px) {
        align-items: center;
        justify-content: center;
        height: 25px;
    }

    &__title {
        font-family: Gilroy-Medium;
        color: white;
        letter-spacing: 1.5px;
        font-size: 14px;
        line-height: 14px;
        opacity: 50%;
        white-space: nowrap;
        align-self: center;
        text-transform: uppercase;
        margin: 0px;

        @media screen and (max-width: 850px) {
            font-size: 10px !important;
            line-height: 10px;
        }
    }

    &__value {
        font-family: Gilroy-Regular;
        color: white;
        font-size: 80px;
        position: relative;
        margin-left: 10px;
        line-height: 80px;
        height: 66px;
        margin-bottom: 0px;

        @media screen and (max-width: 850px) {
            font-size: 20px !important;
            line-height: 20px !important;
            height: 20px;
        }
    }

    &__delta {
        position: absolute;
        bottom: -50px;
        display: flex;
        left: 5px;
        font-size: 14px;
        font-family: Gilroy-Regular;
        color: #16AEF3;
        text-transform: uppercase;

        img {
            margin-right: 5px;
        }

        @media screen and (max-width: 850px) {
            bottom: -20px;
            left: -10px;
            font-size: 12px;
        }
    }
}