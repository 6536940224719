.treehouse-topics {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.treehouse-title {

    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0px;
    margin-top: 28px;
    color: white;
    font-family: Gilroy-Bold;
    font-size: 32px;

    @media screen and (max-width: 500px){
        font-size: 25px;
    }
}

.topic {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    margin-bottom: 16px;

    h2 {
        color: white;
        font-family: Gilroy-Bold;
        font-size: 23px;
        margin: 0px;
        margin-top: 12px;
        margin-bottom: 16px;

        @media screen and (max-width: 500px){
            font-size: 18px;
        }
    }

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgba(255,255,255,0.5);
        font-family: Gilroy-Regular;
        font-size: 16px;


        

        @media screen and (max-width: 800px){
            font-size: 13px;
            & > *:nth-of-type(2),
            & > *:nth-of-type(3) {
                display: none;
            }
        }

        
    }

    &__open {
        color: white;
        font-family: Gilroy-Bold;
        text-decoration: none;
        display: flex;
        align-items: center;

        img {
            margin-left: 10px;
        }
    }

    &__separator {
        margin-right: 10px;
        margin-left: 10px;

        @media screen and (max-width: 800px){
            margin-right: 3px;
            margin-left: 3px;
        }
    }

    &__user-pic {
        border: 0.5px gray solid;
        border-radius: 100px;
        height: 24px;
        width: 24px;
        margin-right: 10px;

        @media screen and (max-width: 800px){
            height: 20px;
            width: 20px;
            margin-right: 5px;
        }
    }
}