.journalling {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 190px 450px 320px 400px;
    gap: 20px;
    margin-left: 80px;
    margin-right: 80px;
  
    grid-template-areas: 
        "total-journals journals-today average-journal-time average-journal-duration"
        "journalling-time journalling-time emotional-slider emotional-slider"
        "mood-last-entry mood-last-entry mood-last-entry mood-last-entry"
        "journalling-status journalling-status latest-journals latest-journals ";


}