.me-journalling {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 530px 1050px min-content;
    gap: 20px;
    grid-template-areas: 
        "about about survey survey"    
        "hourly-uses hourly-uses hourly-uses hourly-uses"
        "all-surveys all-surveys all-surveys all-surveys";

    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 530px 530px 1050px min-content;
        grid-template-areas: 
        "about"  
        "survey"  
        "hourly-uses"
        "all-surveys";
    }
}


.me {

    &__all-surveys {
        height: min-content;
    }

    &__alert {
        color: white;
        font-family: Gilroy-Bold;
        text-align: center;
        padding-top: 200px;
        height: 600px;
        vertical-align: center;
        font-size: 25px;
    }

    &__header {
        display: flex;
        grid-area: main-header;
        align-items: center;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__back {
        background-color: transparent;
        outline: none;
        border: none;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing:2px;
        align-self: center;
        font-family: Gilroy-Medium;
        width: 100px;

        
        &:hover {
            cursor: pointer;
        }

        img {
            margin-right: 10px;
        }
    }

    &__title {
        text-align: center;
        letter-spacing: -2%;
        text-transform: lowercase;
        vertical-align: center;
        font-size: 32px;
        font-family: Gilroy-Bold;
        color: white;
        grid-area: section-title;
        flex: 1;
    }


    &__spacing {
        width: 100px;
    }
}