.mood-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    flex: 1;


    &__title {
        font-family: Gilroy-Regular;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    &__percentage {
        font-family: Gilroy-Regular;
        font-size: 30px;
        color: white;
        margin-bottom: 14px;

        b {
            margin-left: 3px;
            font-size: 18px;
        }
    }

    &__img {
        object-fit: fill;
        height: 70px;
        margin-bottom: 15px;
    }
}
