
@mixin absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.line-chart-with-fill {


    &__text {
        color: white;
        height: 40px;
        font-size: 14px;
        font-family: Gilroy-Medium;
        transform: translateY(15px);
    }

    &__line {
        color: white;
        stroke: white;
    }
}
