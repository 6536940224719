.flower {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
    justify-content: center;

    & > img {
        @media screen and (max-width: 600px) {
            height: 90px !important;
        }
    }
    
    &:last-child {
        margin-right: 0px;
    }

    h1 {
        font-family: Gilroy-Bold;
        color: white;
        font-size: 28px;
        margin-bottom: 0px;

        @media screen and (max-width: 500px) {
            font-size: 22px;
        }
    }
}