.invite-users {
    padding: 32px;
    position: relative;

    h1 {
        font-family: Gilroy-Bold;
        color: white;
        font-size: 22px;
        margin-bottom: 40px;
        margin-top: 0px;
    }

    &__new-invite {
        position: absolute;
        right: 10px;
        top: -10px;
        cursor: pointer;
    }

    &__pending {
        position: absolute;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.16em;
    }

    &__status-wrapper{
        display: flex;
        justify-content: flex-end;
    }

    &__remove-invitation {
        height: 24px;
        width: 24px;
        cursor: pointer;
    }

    &__table {
        display: grid;
    }

    &__img {
        height: 40px;
        width: 40px;
        border: 1px solid #FFFFFF66;
        border-radius: 50px;
    }

    &__table {
        display: flex;
    }

    &__pending {
        font-family: Gilroy-Medium;
        font-size: 13px;
        letter-spacing: 0.16em;
        color:#19C5D7;
    }

    &__column {
        display: flex;
        flex-direction: column;
        grid-auto-rows: 50px;

        &:nth-of-type(2) {
            flex: 1;
            margin-left: 11px;
        }

        &:nth-of-type(3) {
            flex: 1;
            margin-right: 30px;
        }

        & > * {
            height: 60px;
            display: flex;
            align-items: center;
            color: white;
            font-family: Gilroy-Medium;
            font-size: 16px;
        }
    }

    &__img {
        flex: 1;
    }  
}

.add-user {
    display: flex;
    flex-direction: column;
    margin: 30px;

    h4 {
        font-family: Gilroy-Bold;
        color: white;
        font-size: 28px;
        margin: auto;
        margin-bottom: 40px;
    }

    & > .one-line-input {
        margin-bottom: 40px;
    }

    &__question {
        color: white;
        font-family: Gilroy-Regular;
        font-size: 12px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
}