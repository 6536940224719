
.score {
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    color: white;
    align-self: center;
    font-family: Gilroy-Thin;
    font-size: 80px;
}

.my-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__waiting-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;

        h1 {
            font-family: Gilroy-Bold;
            color: white;
        }
    }

    h1 {
        position: absolute;
        top: 20px;
        font-family: Gilroy-Bold;
        color: white;

    }

    &__horizontal {
        display: flex;
        padding: 40px !important;
        padding-top: 15px !important;
    }
    

    &__title {
        font-family: Gilroy-Bold;
        font-size: 32px;
        color: white !important;
        padding-top: 40px;
        padding-left: 40px;
        margin: 0px;;
    }

    &__right {
        width: 247px;
        display: flex;
        flex-direction: column;


        p {
            padding: 0px;
            width: 247px;
            font-family: Gilroy-Bold;
            font-size: 30px;
            color: white;
            margin-bottom: 20px;

            strong {
                background: -webkit-linear-gradient(3.06deg, #6821FF -33.08%, #0085FF 33.02%, #00D9F6 100.51%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        
    }

    &__left {
        flex: 1;
    }

    &__message {
        display: flex;
        bottom: 20px;
        align-items: center;
        position: absolute;
        right: 30px;

        img {
            margin-left: 20px;
            margin-right: -20px;;
        }

        p {
            max-width: 350px;
            color: white;
            font-family: Gilroy-Bold;
            font-size: 13px;
            padding: 0px;
            margin: 0px;
        }
    }
}

