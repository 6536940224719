.currently-in-use {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;

    &__not-enough-data {
        color: white;
        font-family: Gilroy-Bold;
    }
}