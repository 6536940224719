.pioneers {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 190px 430px 80px 190px min-content;
    gap: 20px;
    margin-left: 80px;
    margin-right: 80px;
  
    grid-template-areas: 
        "activated-pioneers active-today average-age total-journals"
        "gender-by-age gender-by-age most-active most-active"
        "section-title section-title section-title section-title"
        "gender average-age-most-active most-active-avg-sprays-per-day most-active-journals-in-interval"
        "all-pioneers all-pioneers all-pioneers all-pioneers";

    &__section-title {
        text-align: center;
        letter-spacing: -2%;
        text-transform: lowercase;
        vertical-align: center;
        padding-top: 20px;
        font-size: 32px;
        font-family: Gilroy-Bold;
        color: white;
        grid-area: section-title;
    }


}