#root {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

}

body {
  background-color: #06081E;
  width: 100%;
  box-sizing: border-box;
}

.contents {
  padding-top: 130px;
  padding-bottom: 30px;
  box-sizing: border-box;  
  align-self: stretch;
  align-self: center;

  width: 99%;
  
  @media screen and (min-width: 850px) {
    width: 95%;
  }
  
  @media screen and (min-width: 1800px) {
    width: 1300px;
  }
  
}

.line {
  height: 1px;
  background-color: rgba(255,255,255,0.2);
  width: 100%;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url(./assets/fonts/Gilroy-Extrabold.otf);
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url(./assets/fonts/Gilroy-Light.otf);
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url(./assets/fonts/Gilroy-Regular.otf);
}

@font-face {
  font-family: 'Gilroy-Thin';
  src: url(./assets/fonts/Gilroy-Thin.woff2);
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url(./assets/fonts/Gilroy-Medium.otf);
}

@font-face {
  font-family: 'Gilroy-Semibold';
  src: url(./assets/fonts/Gilroy-Semibold.otf);
}
