.cluster {
    img {
        top: 25%;
        left: 25%;
        bottom: 25%;
        right: 25%;
    }
}

.pioneers-map {
    border-radius: 15px;
    overflow: hidden;

    &__tag {
        background-color: white;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        position: absolute;
        z-index: 10000000000;
        border-radius: 16px;
        height: 90px;
        width: 180px;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        border: 1px solid rgba(95, 149, 255, 0.25);
        align-items: flex-start;
        justify-content: center;
        padding-left: 20px;
    }

    &__div-count {
        color: white;
        font-size: 20px;
        font-family: Gilroy-Bold;
        opacity: 0.9;
        line-height: 20px;
    }

    &__div-country {
        color: white;
        font-size: 13px;
        line-height: 12px;
        font-family: Gilroy-Medium;
        opacity: 0.5;
    }
}
