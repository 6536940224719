
@mixin absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.line-chart {
    &__tag {
        width: 180px;
        height: 95px;
        pointer-events: none;
        border-radius: 12px;
        position: relative;

        &-content {
            position: absolute;
            display: flex;
            padding: 20px;
            align-items: flex-start;
            flex-direction: column;
            z-index: 10;
        }

        &-background {
            z-index: 1;
            background: linear-gradient(162.06deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
            backdrop-filter: blur(5px);
            border-radius: 16px;
            border: 1px solid rgba(95, 149, 255, 0.25);
            @include absolute();
        }
        
        &-title {
            font-family: Gilroy-Bold;
            text-align: left;
            margin-bottom: 12px;
            font-size: 20px;
        }
        
        &-hour {
            font-family: Gilroy-Medium;
            font-size: 14px;
            opacity: 0.5;
        }
    }


    &__line1 {
        opacity: 1;
        /* White */
        border: 0.6px solid #248FF1;
        filter: blur(1px);
    }

    &__line2 {
        opacity: 1;
        /* White */
        border: 1px solid #248FF1;
        filter: blur(1.5px);
    }

    &__line3 {
        filter: blur(4px);
    }

    &__line4 {
        backdrop-filter: blur(19px);
    }

    &__line5 {
        filter: blur(40px);
    }

    &__text {
        color: white;
        height: 40px;
        font-size: 14px;
        font-family: Gilroy-Medium;
        transform: translateY(15px);
    }

    &__line {
        color: white;
        stroke: white;
    }
}


.score-chart {
    margin-left: 30px;
    position:'relative';
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    

    &__toggle {
        position: 'absolute';
        right: 30px;
        top: 45px;
        display: flex;
        align-items: center;
        height: 30px;
        background-color: transparent;
        border: 1px solid gray;
        font-family: Gilroy-SemiBold;
        color: white;
        border-radius: 50px;
        padding: 0px 10px;
        cursor: pointer;
    }


    &__wrapper {
        position: absolute;
        z-index: 1000;
    }

    &__score {
        color: white;
        font-family: Gilroy-Light;
        font-size: 60px;
        line-height: 50px;
        margin-left: 15px;
        margin-top: -4px;
        pointer-events: none;
        display: flex;
        align-items: flex-start;

        img {
            margin-right: 5px;
        }

    }

    &__gradient-wrapper {
        width: 100%;
        height: 100%;
    }

    &__gradient {
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, rgba(102, 132, 255, 0) 5.96%, #66C7FF 117.01%);
    }

    &__dot {
        position: absolute;
    }

    &__tag {
        position: absolute;
        left: 30px;

        div {
            position: absolute;
            left: -75px;
            top: -40px;
            font-family: Gilroy-Regular;
            color: white
        }
        
        img {
            position: absolute;
            left: -88px;
            top: -60px;
        }
    }

    &__line {
        position: absolute;
        top: 7px;
    }

    &__shadow {
        fill: 'white'
    }

    &__sidebar {
        background-color: rgba(255,255,255,0.1);
        width: 8px;
        position: absolute;
        left: -20px;
        bottom: 0px;
        z-index: 1000;
        border-radius: 100px;
    }

    &__sidebar-inner {
        z-index: 1000;
        position: absolute;
        background: linear-gradient(3.06deg, #6821FF -33.08%, #0085FF 33.02%, #00D9F6 100.51%);
        width: 8px;
        left: 0px;
        bottom: 0px;
        border-radius: 100px;
    }
}
