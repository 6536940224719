.footer {

    display: flex;
    align-self: stretch;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    height: 70px;
    align-items: center;
    padding-right: 40px;
    padding-left: 40px;

    &__company, &__rights, &__download-report {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: white;
        font-family: Gilroy-Semibold;

        img {
            margin-right: 8px;
        }
    };

    &__download-report {
        font-family: Gilroy-Regular;
    }

    &__company, &__rights {
        flex: 1;
        opacity: 0.6;  
    }

    &__rights {
        text-align: end;
    }

}